import { ms } from "date-fns/locale";
import React, { useCallback, useEffect, useState } from "react";

import "./styles.scss";

const DBO = () => {
  const times = [
    "3:00",
    "3:15",
    "3:30",
    "3:45",
    "4:00",
    "4:15",
    "4:30",
    "4:45",
    "5:00",
    "5:15",
    "5:30",
    "5:45",
    "6:00",
    "6:15",
    "6:30",
    "6:45",
    "7:00",
    "7:15",
    "7:30",
    "7:45",
  ];

  const generateTimes = () => {
    const generated = [];

    times.forEach(t => generated.push({ time: t, events: [] }));

    return generated;
  };

  const countries = [
    "🇨🇺 Cuba",
    "🇳🇱 Netherlands",
    "🇧🇷 Brazil",
    "🇨🇦 Canada",
    "🇧🇪 Belgium",
    "🏴󠁧󠁢󠁳󠁣󠁴󠁿 Scotland",
    "🇩🇪 Germany",
    "🇮🇳 India",
    "🇺🇸 USA",
    "🇬🇷 Greece",
    "🇺🇳 Independent",
  ];

  const generateCountries = () => {
    const generated = [];

    countries.forEach(c => generated.push({ country: c, events: [] }));

    return generated;
  };

  const sports = [
    "The Sphinx",
    "Piju Pong",
    "Humpty Dumpty",
    "Fussball Shootout",
    "Tower of Babylon",
    "Disco Bolus",
    "Biathlon",
    "Tin Cup",
    "Dizzybat",
    "Thor's Hammerslagen",
  ];

  const embargoes = {
    "🇬🇷 Greece": "🇨🇦 Canada",
    "🇧🇪 Belgium": "🇧🇷 Brazil",
    "🇨🇦 Canada": "🇬🇷 Greece",
    "🇧🇷 Brazil": "🇧🇪 Belgium",
  };

  const peacetimes = [
    "Humpty Dumpty",
    "Dizzybat",
    "Biathlon",
  ]

  const [bracket, setBracket] = useState();
  const [matchSchedules, setMatchSchedules] = useState([]);
  const [byTime, setBytime] = useState(generateTimes());
  const [byCountry, setByCountry] = useState(generateCountries());

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }

    return array;
}

  const getMatches = useCallback(() => {
    const matches = [];
    const vs = {};
    const updatedMatchSchedules = [...matchSchedules];
    const updatedByTime = [...byTime];
    const updatedByCountry = [...byCountry];

    sports.forEach(sport => {
      const newMatch = {
        sport,
        matchups: [],
      };

      while (newMatch.matchups.length < Math.ceil(countries.length / 2)) {
        const shuffledCountries = shuffleArray(countries);

        const first = shuffledCountries.find(country => !newMatch.matchups.some(matchup => matchup.includes(country)));

        const eligibleCountries = shuffledCountries.filter(country => {
          const isWarring = embargoes[first] === country;

          return country !== first && (!isWarring || peacetimes.some(p => p === sport)) && !newMatch.matchups.some(matchup => matchup.includes(country));
        });

        // Lowers the chance of playing vs the same country multiple times in the opening round
        eligibleCountries.sort((a, b) => {
          const isAPrev = vs[first]?.some(m => m === a);
          const isBPrev = vs[first]?.some(m => m === b);

          return isAPrev === isBPrev ? 0 : isAPrev ? 1 : -1;
        });

        newMatch.matchups.push([first, eligibleCountries[0]]);

        if (vs[first]) vs[first].push(eligibleCountries[0]);
        else vs[first] = [eligibleCountries[0]];

        const eligibleTimes = times.filter(t => {
          const isAAvail = !updatedMatchSchedules.some(ms => ms.time === t && ms.countries.some(c => c === first));
          const isBAvail = !updatedMatchSchedules.some(ms => ms.time === t && ms.countries.some(c => c === eligibleCountries[0]));
          const isSportUnique = !updatedMatchSchedules.some(ms => ms.time === t && ms.sport === sport);
  
          return isAAvail && isBAvail && isSportUnique;
        });

        updatedMatchSchedules.push({
          countries: [first, eligibleCountries[0]],
          sport,
          time: eligibleTimes[0],
        });
      }

      matches.push(newMatch);
    });

    updatedMatchSchedules.forEach(ms => {
      const timeIndex = updatedByTime.findIndex(item => item.time === ms.time);
      const countryAIndex = updatedByCountry.findIndex(item => item.country === ms.countries[0]);
      const countryBIndex = updatedByCountry.findIndex(item => item.country === ms.countries[1]);

      updatedByTime[timeIndex].events.push(countryBIndex >= 0 ? `${ms.countries[0]} vs ${ms.countries[1]} [${ms.sport}]` : '');
      updatedByCountry[countryAIndex].events.push(countryBIndex >= 0 ? `${ms.time}: ${ms.sport} vs ${ms.countries[1]}` : `${ms.time}: ${ms.sport} vs 🇺🇳 Independent`);
      if (countryBIndex >= 0) updatedByCountry[countryBIndex].events.push(`${ms.time}: ${ms.sport} vs ${ms.countries[0]}`);
    });

    setBracket(matches);
    setMatchSchedules(updatedMatchSchedules);
    setBytime(updatedByTime);
    setByCountry(updatedByCountry);
  }, [byCountry, byTime, countries, embargoes, matchSchedules, peacetimes, sports, times]);

  useEffect(() => {
    if (!bracket) getMatches();
  }, [bracket, getMatches]);

  return (
    <div className="dbo">
      <div className="schedule">
        <h2>Schedule</h2>
        <div className="by-time">
          {byTime.map(item => (
            <div className="time" key={item.time}>
              <h4>{item.time}</h4>
              {item.events.map(event => (
                <p>{event}</p>
              ))}
            </div>
          ))}
        </div>
      </div>

      <div className="schedules">
        <h2>Country Schedules</h2>
        <div className="by-country">
          {byCountry.map(item => (
            <div className="country" key={item.country}>
              <h4>{item.country}</h4>
              {item.events.map(event => (
                <p>{event}</p>
              ))}
            </div>
          ))}
        </div>
      </div>

      <div>
        <h2>By Sport</h2>
        {bracket?.map(match => (
          <div className="sport" key={match.sport}>
            <h4>{match.sport}:</h4>
            {match.matchups.map(m => (
              <p className="matchup" key={`${m[0]} vs ${m[1]}`}>{`${m[0]} vs ${m[1]} at ${matchSchedules?.find(ms => ms.countries.every(c => c === m[0] || c === m[1])).time}`}</p>
            ))}
          </div>
        ))}
      </div>
    </div>
  );

};

export default DBO;