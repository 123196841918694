import React, { useEffect, useRef, useState } from 'react';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';

import { compact } from 'lodash';
import ReactHighcharts from 'react-highcharts';

import Updater from '../components/Updater'

import { TextareaAutosize } from '@material-ui/core';

import { firebaseConfig } from '../../../utils/firebaseConfig';

import './styles.scss';

const Wishlists = () => {
  const firebaseApp = firebase.apps && firebase.apps.length ? firebase.apps[0] : firebase.initializeApp(firebaseConfig);
  const db = firebaseApp.firestore();

  const [chartConfig, setChartConfig] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [isPendingCleanup, setIsPendingCleanup] = useState(false);
  const [openUsers, setOpenUsers] = useState([]);
  const [overloadedWishlists, setOverloadedWishlists] = useState([]);
  const [pendingWishlists, setPendingWishlists] = useState(false);
  const [pendingWishlistUpdate, setPendingWishlistUpdate] = useState(false);
  const [showOverloaded, setShowOverloaded] = useState(false);
  const [usersJSON, setUsersJSON] = useState('');
  const [userWishlists, setUserWishlists] = useState();

  const chart = useRef(null);

  const getWishlists = async () => {
    setPendingWishlists(true);

    let headers = new Headers();
    headers.append("Accept", "*/*");
    headers.append("Accept-Language", "en-US,en;q=0.5");
    headers.append("Content-Type", "text/plain");

    const userWishlistsTxt = await fetch('https://us-central1-fortnite-dashboard-b545a.cloudfunctions.net/accounts',
    {
      body: JSON.stringify({ isNatalie: "hi"}),
      headers,
      method: 'POST',
      redirect: 'follow'
    })
    .then(res => res.text())
    .catch(e => console.log(`Error fetching accounts: ${e}`));

    const userWishlistsTemp = JSON.parse(userWishlistsTxt);

    userWishlistsTemp.sort((a, b) => {
      const aTime = new Date(a.creationTime).getTime();
      const bTime = new Date(b.creationTime).getTime();

      return +bTime - +aTime;
    });

    const overloadedWishlistsTemp = userWishlistsTemp.filter(user => {
      const accountCreatedTime = new Date(user.creationTime).getTime();

      if ((user.wishlist?.length > 3) && (+accountCreatedTime > 1590238801000)) console.log(accountCreatedTime, user)

      return (user.wishlist?.length > 3) && (+accountCreatedTime > 1590238801000);
    });

    setUserWishlists(userWishlistsTemp);
    setOverloadedWishlists(overloadedWishlistsTemp);
    setPendingWishlists(false);
  }

  const handleOpenUsers = (uid) => {
    const openUsersTemp = JSON.parse(JSON.stringify(openUsers));

    if (openUsersTemp.some(openUser => openUser === uid)) {
      const uidIndex = openUsersTemp.indexOf(uid);

      openUsersTemp.splice(uidIndex, 1);
    } else openUsersTemp.push(uid);

    setOpenUsers(openUsersTemp);
  }

  const toggleOverloaded = () => {
    setShowOverloaded(!showOverloaded);
  }

  const chartColors = () => {
    const colors = [];

    for (let i = 0; i < 10; i++) {
      colors.push(ReactHighcharts.Highcharts.color('rgb(201, 46, 219)').brighten((i - 3) / 7).get());
    }

    return colors;
  }

  const getChart = () => {
    if (!userWishlists) return;

    const chartConfigTemp = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: '',
      },
      plotOptions: {
        pie: {
            allowPointSelect: true,
            borderColor: null,
            colors: chartColors(),
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f}%'
            }
        }
      },
      series: [
        {
          name: 'Type',
          colorByPoint: true,
          data: []
        }
      ]
    };

    const items = {};
    const names = {};

    userWishlists.forEach(({wishlist}) => {
      wishlist.forEach(item => {
        if (!items[item.category]) items[item.category] = 0;
        if (!names[item.name]) names[item.name] = 0;

        items[item.category]++;
        names[item.name]++
      })
    });

    Object.keys(items).forEach(name => {
      const capName = `${name.substr(0, 1).toUpperCase()}${name.substr(1)}`;

      chartConfigTemp.series[0].data.push({
        name: capName,
        y: items[name],
      });
    });

    chartConfigTemp.series[0].data.sort((a, b) => b.y - a.y);

    setChartConfig(chartConfigTemp);
  }

  const cleanUpWishlists = async () => {
    setIsPendingCleanup(true);

    db.collection('item-shop').get().then((rawItems) => {
      const allItems = rawItems.docs.map(item => item.data());

      db.collection('userPrefs').where('hasWishlist', '==', true).get()
      .then(prefsRaw => {
        if (prefsRaw && !prefsRaw.empty) {
          const userPrefs = prefsRaw.docs.map(item => item.data());
          const promises = [];
          
          userPrefs.forEach(userPref => {
            const newWishlist = [];

            userPref.wishlist.forEach(item => {
              const trueItem = allItems.find(allItem => allItem.name === item.name);

              if (trueItem) newWishlist.push(trueItem);
              else console.warn(`Unable to find or update item: ${item.name}. Gave id: ${item.id}`);
            });

            promises.push(db.collection('userPrefs').doc(userPref.userId).update({ wishlist: newWishlist })
            .catch(err => console.error(`Error updating wishlist for ${userPref.userId}: ${err}`)));
          });

          Promise.all(promises).then(() => {
            console.log(`Updated ${promises.length} wishlists.`);
            setIsPendingCleanup(false);
        });
        }
      });
    });
  }

  const sendWishlists = () => {
    let users;

    setErrorMessage('');
    setPendingWishlistUpdate(true);

    try {
      users = JSON.parse(usersJSON).users;
    }
    catch {
      return setErrorMessage('User list not formed correctly.');
    }

    if (!users) return setErrorMessage('User list not formed correctly.');

    db.collection('wishlists').get().then(rawWishlists => {
      const wishlists = rawWishlists.docs.map(item => item.data()).filter(l => l.userIds.length);

      if (wishlists.length) {
        const subscribers = [];

        wishlists.forEach(listItem => {
          listItem.userIds.forEach(id => {
            const currentUser = users.find(u => u.localId === id);
            const subscriberIndex = subscribers.findIndex(s => s.id === id);

            if (subscriberIndex >= 0) subscribers[subscriberIndex].items.push(listItem.name);
            else if (currentUser) subscribers.push({ id, email: currentUser.email, items: [listItem.name] });
            else {
              setErrorMessage('Error while creating email list.');
            }
          });
        });

        fetch('https://us-central1-fortnite-dashboard-b545a.cloudfunctions.net/send-wishlist-emails', {
          method: 'POST',
          cache: 'no-cache',
          mode: 'no-cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(subscribers)
        })
        .then(() => {
          setPendingWishlistUpdate(false);
        })
        .catch(err => console.log(`Error sending subscriber data: ${err}`));
      }
    });
  }

  useEffect(() => {
    getWishlists();
  }, []);

  useEffect(() => {
    if (userWishlists && !chartConfig) getChart();
  });

  return (
    <div key="wishlists-container" className="wishlists-container">
      {Boolean(errorMessage?.length) &&
        <div className="banner error">{errorMessage}</div>
      }

      {pendingWishlists
          ?   <p>Fetching wishlists</p>
          :   <button className="get-wishlists cta" onClick={getWishlists}>Refresh wishlists</button>
      }

      <button className="clean-wishlists cta" onClick={cleanUpWishlists}>Clean up Wishlists {isPendingCleanup && <i className="material-icons spinner">autorenew</i>}</button>

      <Updater
        id="wishlist-sender"
        className="wishlist-sender"
        fields={[
          {
            id: 'usersJSON',
            element: TextareaAutosize,
            props: {
              onChange: event => setUsersJSON(event.target.value),
              value: usersJSON,
              placeholder: 'Manually send wishlists by pasting the auth JSON here.',
              minRows: 6
            }
          }
        ]}
        submitDisabled={!usersJSON.length}
        onSubmit={sendWishlists}
        submitContent={<>Send wishlists {pendingWishlistUpdate && <i className="material-icons spinner">autorenew</i>}</>}
      />

      <hr />

      <div className="user-wishlists-container">
        {userWishlists
          ? (
            <>
              <h3>
                {!showOverloaded && <ReactHighcharts config={{...chartConfig}} ref={chart} />}
                <span>{userWishlists.length} users with wishlists</span>

                {Boolean(overloadedWishlists.length) && (
                  <span className="overload" onClick={toggleOverloaded}> {overloadedWishlists.length} overloaded wishlists</span>
                )}
              </h3>

              <div className="list">
                {compact(userWishlists.map(({ creationTime, displayName, email, lastSignInTime, id, wishlist, wishlistId }) => {
                  if (showOverloaded && !(wishlist?.length > 3)) return null;

                  return (
                    <div className="user" key={id}>
                      <div className="top" onClick={() => handleOpenUsers(id)}>
                        <div className="name-container">
                          <div className="display-name">{displayName || email}</div>
                          {displayName && <div className="email">{email}</div>}
                          <div className="uid">{id}</div>
                          <div className="creation-time">Account created: {creationTime}</div>
                        </div>

                        {wishlistId && (
                          <span>
                            Public wishlist: <a href={`https://fortnitedashboard.com/wishlist/${wishlistId}`} target="_blank" rel="noopener noreferrer">{wishlistId}</a>
                          </span>
                        )}

                        <span className="last-signin">Last log in: {lastSignInTime}</span>
                        <span className="wishlist-amount">{`(${wishlist.length})`}</span>
                      </div>

                      {openUsers.some(openUser => openUser === id) && (
                        <ul>
                          {wishlist.map(({ name }) => (
                            <li key={name}>{name}</li>
                          ))}
                        </ul>
                      )}
                    </div>
                  )
                }))}
              </div>
            </>
          )
          : 'Loading wishlists'
        }
      </div>
    </div>
  );
}

export default Wishlists;