const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;

export const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: "fortnite-dashboard-b545a.firebaseapp.com",
  databaseURL: "https://fortnite-dashboard-b545a.firebaseio.com",
  projectId: "fortnite-dashboard-b545a",
  storageBucket: "fortnite-dashboard-b545a.appspot.com",
  messagingSenderId: "743744717344",
  appId: "1:743744717344:web:58ef24460740f03d"
};